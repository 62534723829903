@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

:root {
  --dark: #101020;
  --main: #131321;
  --main600: #040407;
  --main500: #08080d;
  --main400: #0b0b14;
  --main300: #0f0f1a;
  --main200: #42424d;
  --main100: #a1a1a6;

  --accentMain: #6337d4;
  --accentSecondary: #27196e;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--main);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1hp60fq {
  background: var(--dark)!important;
}

.custom-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 5px -1px 7px 2px;
}

form {
  background: #ffffff;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  padding: 3rem 2rem;
  border-radius: 13px;
}

.MuiInputBase-input, .MuiOutlinedInput-input {
  color: #000000!important;
  color: rgb(19, 19, 32)
}

.css-uirix7-MuiInputBase-root-MuiOutlinedInput-root, .MuiOutlinedInput-root {
  border: .1px solid transparent!important;
}

.css-uirix7-MuiInputBase-root-MuiOutlinedInput-root:hover, .MuiOutlinedInput-root:hover {
  border: .1px solid #000000!important;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  text-transform: uppercase;
}

.logo p {
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.6px;
  color: white;
}

.gradient-text {
  background: -webkit-linear-gradient(80deg, #6337d4, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.MuiContainer-root {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.css-h4y409-MuiList-root {
  height: 100%;
  background: var(--main)!important;
  padding: 1rem!important;
}

.modal p, .modal h2, .modal h3, .modal h4, .modal h5, .modal h6 {
  color: var(--main)!important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline:hover {
  border-color: var(--main)
}

@media screen and (max-width: 768px) {
  .MuiContainer-root {
      padding-top: 2rem;
      padding-bottom: 2rem;
  }
}

/* card-hover */
/* GameList.css */
.game-card {
  position: relative;
  overflow: hidden;
}

.card-header {
  position: absolute;
  transform: translateY(100%);
  /* bottom: 0; */
  width: 100%;
  background: rgba(19, 19, 32, 0.7);
  color: white;
  text-align: center;
  transition: all 0.25s ease-in-out;
}

.MuiCardHeader-root.card-header {
  padding: .7rem 0!important;
}

.card-header span {
  font-size: 1.1em;
  font-family: "Rubik Iso", sans-serif;
}

.game-card:hover .card-header {
  transform: translateY(-100%)

}

/* end card hover */

/* cookie modal */
.cookie-block {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 8xl;
  border-radius: 0.5rem;
  background-color: var(--main400);
  opacity: 0.9;
  color: #fff;
  margin: 0.625rem;
  margin-bottom: 0.25rem;
  padding: 1.25rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 10;
}

.cookie-text {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.cookie-accept {
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--accentMain);
  color: var(--white);
  border-radius: 0.25rem;
  border: 0;
  padding: 0.5rem 1rem;
  margin-left: 0.625rem;
  font-size: 1em;
  cursor: pointer;
}

.cookie-close-wrap {
  border-radius: 0.625rem;
  position: absolute;
  bottom: -5%;
  right: 1%;
  transform: translateY(-50%);
}

.cookie-close-btn {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.25rem 1rem;
  margin-left: 0.625rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--main900)
}

.cookie-close-btn:hover {
  transform: scale(1.25);
}

.cookie-more {
  color: #fff;
  margin-left: 0.125rem;
  text-decoration: underline;
  text-underline-offset: 0.125rem;
  font-size: 0.9em;
}